import { Trans, useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";

import { EnvironmentContext } from "../EnvironmentContext";
import { useContext } from "react";
import { useStoreBackend } from "../hooks/useStoreBackend";

export type InstallProps = {
	/**
	 * Id off store item
	 */
	id: string;

	/**
	 * The store item to be installed
	 */
	storeItem: string;

	/**
	 * Whether or not the store item can be updated
	 */
	outdated?: boolean | null;

	/**
	 * State setter, to hide the install modal
	 */
	setShow: Dispatch<SetStateAction<boolean>>;

	/**
	 * Triggers when the installation was successfull;
	 */
	installSuccessfull?: () => void;
};

export const InstallModal = (props: InstallProps): JSX.Element => {
	const [status, setStatus] = useState<number>();
	const [disabled, setDisabled] = useState<boolean>(true);
	const [loading, setLoading] = useState(false);

	const { environment } = useContext(EnvironmentContext);
	const { t } = useTranslation();
	const sb = useStoreBackend();

	const auth = useAuth();
	let profile: any;

	if (auth.isAuthenticated && auth.user) {
		profile = auth.user.profile;
	}
	/**
	 * Translates install response into a readable error message
	 * @param status Response
	 * @returns Status message
	 */
	const translateStatusMessage = (status: number) => {
		let message: string = "";

		switch (status) {
			case 204:
				message = "De installatie is geslaagd.";
				break;
			case 400:
				message = "Er is iets misgegaan met de installatie.";
				break;
			case 401:
				message = "U bent niet geauthoriseerd om te installeren.";
				break;
			case 403:
				message = `${profile.given_name} ${profile.family_name} mag deze actie niet uitvoeren.`;
				break;
			case 500:
				message = "Er is een fout op de server.";
				break;
			case 502:
				message = "Bad gateway";
				break;
			case 503:
				message = "De server is momenteel buiten gebruik.";
				break;
		}

		return message;
	};

	/**
	 * Check if the intall button should be disabled
	 */
	const checkDisabled = () => {
		if (environment === "") {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	};

	useEffect(() => {
		checkDisabled();
	}, [environment]);

	/**
	 * Decides what the install modal text should be.
	 * @returns Text for the install modal. Either 'Updaten' or 'Installeren'
	 */
	const modalText = () => {
		let text;
		let button;

		switch (props.outdated) {
			case true:
				text = t("ON_UPDATE").toLowerCase();
				button = t("ON_UPDATE")
				break;
			default:
				text = t("INSTALL").toLowerCase();
				button = t("INSTALL")
		}

		return {
			text,
			button
		};
	};

	/**
	 * Install store item
	 */
	const installStoreItem = () => {
		if (!environment) {
			console.error("No environment specified or returned");
			return;
		}

		if (!props.id) return;

		setLoading(true);
		sb.installStoreItem(props.id)
			.then((response) => {

				if (props.installSuccessfull)
					props.installSuccessfull();

				// Show final status
				setStatus(response.status);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<div className="modal">
			<div className="modal-backdrop"></div>
			<div className="modal-window">
				<div className="modal-header">
					<div className="modal-title">{t("ON_UPDATE")}</div>
					<button onClick={() => props.setShow(false)} title="Sluiten" className="modal-close">
						<i className="icon icon-cross"></i>
					</button>
				</div>
				<div className="modal-body">
					{!status && !loading && (
						<>
							<p>
								<Trans i18nKey="INSTALL_MODAL_DESCRIPTION"
									values={{
										storeItem: props.storeItem,
										action: modalText().text,
										environment: environment
									}}
									components={{
										storeItem: <strong>{ }</strong>,
										environment: <strong>{ }</strong>
									}}
								/>
							</p>
							<div className="modal-buttons">
								<button
									className="button button-small button-secondary button-icon"
									onClick={() => props.setShow(false)}
								>
									<i className="icon icon-cross"></i> {t("CANCEL")}
								</button>
								<button
									className="button button-small button-primary button-icon"
									onClick={installStoreItem}
									disabled={disabled}
								>
									<i className="icon icon-tick"></i> {modalText().button}
								</button>
							</div>
						</>
					)}
					{loading && (
						<div className="indicator">
							<div className="indicator-inner">
								<div className="loading-indicator"></div>
								<div className="indicator-title">{t("UPDATING")}</div>
							</div>
						</div>
					)}
					{status && (
						<>
							<p>{translateStatusMessage(status)}</p>
							<div className="modal-buttons align-center">
								<button
									className="button button-small button-secondary button-icon"
									onClick={() => props.setShow(false)}
								>
									<i className="icon icon-cross"></i> {t("CLOSE_TAB")}
								</button>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
