import { useContext, useEffect, useState } from "react";
import { LoadingIndicatorStoreItems } from "../loading-indicator/LoadingIndicatorStoreItems";
import { LoadingType } from "../types/LoadingTypes";
import { NavLink, useNavigate } from "react-router-dom";
import { ItemList } from "../store-items/ItemList";
import { EnvironmentContext } from "../EnvironmentContext";
import { useAuth } from "react-oidc-context";
import { StoreMyAppsItem } from "../store-items/StoreMyAppsItem";
import { StoreItem } from "../objects/StoreItem";
import { Error } from "../objects/Error";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { BackButton } from "../BackButton";
import { useStoreBackend } from "../hooks/useStoreBackend";

/**
 * Displays a list of installed apps, blocks and extensions within the 
 * selected environment
 * @returns 
 */
export const MyApps = (): JSX.Element => {
	const [storeItems, setStoreItems] = useState<StoreItem[]>([]);
	const [storeError, setStoreError] = useState<Error>();
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
	const { t } = useTranslation();
	const auth = useAuth();
	const { environment } = useContext(EnvironmentContext);
	const sb = useStoreBackend();

	// Get apps
	useEffect(() => {

		if (!auth.isAuthenticated)
			return;

		sb.getStoreItems()
			.then(data => {
				setStoreItems((data as StoreItem[]).filter(i => i.installedComputedVersion !== null));
				setLoading(false);
				setLoadingFailed(false);
			})
			.catch((error) => {
				setLoadingFailed(true);
			});
	}, [environment]);

	/**
	 * Get app list
	 * @returns
	 */
	const getApplist = () => {
		return storeItems.map((item, index) => {
			return <StoreMyAppsItem data={item} prevPage="/myapps" key={`si-${index}`} />;
		});
	};

	/**
	 * Desides what to return to the page
	 * @returns Page content
	 */
	const getContent = () => {
		if (loading) {
			return <LoadingIndicatorStoreItems indicator={LoadingType.LOADING} text={t("APPS_LOADING")} />;
		} else if (storeError) {
			return (
				<LoadingIndicatorStoreItems
					indicator={LoadingType.ERROR}
					text={`Er is iets mis gegaan met  inladen van de apps: ${storeError.detail}`}
				/>
			);
		} else if (loadingFailed) {
			return (
				<LoadingIndicatorStoreItems
					indicator={LoadingType.ERROR}
					text={t("APPS_ERROR")}
				/>
			);
		} else if (!environment) {
			return <span>{t("ADD_ENVIRONMENT")}</span>
		} else {
			return getApplist();
		}
	};

	return (
		<>
			<Helmet>
				<title>{t("MY_APPS")} - GeoApps Store</title>
			</Helmet>
			<div className="style-apps cards-block cards-block-list">
				<div className="background"></div>
				<div className="overlay"></div>
				<div className="container">
					<BackButton />
				</div>
				<div className="container">
					<div className="column-left">
						<h2>{t("MY_APPS")}</h2>
						<p>{t("MY_APPS_DESCRIPTION")}</p>
					</div>
					<div className="column-right">
						{storeItems.filter(i => i.outdated).length > 0 &&
							<div className="flex justify-content-end">
								<NavLink className="button button-apps button-icon" to={"/updates"}>
									{t("WATCH_ALL_UPDATES")}
									<i className="icon icon-chevron-right"></i>
								</NavLink>
							</div>
						}
						<div className="cards cards-list">{getContent()}</div>
					</div>
				</div>
			</div>
		</>
	);
};
