import { NavLink, To } from "react-router-dom";

export type CategoryProps = {

    /**
     * Title of the category section
     */
    title: any;

    /**
     * Description show in the category section
     */
    description: any;

    /**
     * Content of the blocks
     */
    content?: React.ReactNode;

    /**
     * Identifier of the content group
     */
    contentIdentifier: string;

    /**
     * The button 'show all'
     */
    moreText: any;

    /**
     * Location the button 'more' navigates to
     */
    moreTo: To;

    /**
     * Classname fo the category section
     */
    className?: string;
}

/**
 * Render a category section on the homepage
 * @param props 
 * @returns 
 */
export const Category = (props: CategoryProps) => {

    const getClassnames = () => {
        let classNames = "cards-block";
        if (props.className) {
            classNames = `${classNames} ${props.className}`;
        }

        return classNames;
    }

    return <div className={getClassnames()}>
        <div className="background"></div>
        <div className="overlay"></div>
        <div className="container">
            <div className="column-left">
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </div>
            <div className="column-right">
                <div className="cards">
                    {props.content}
                </div>
                <div className="cards-block-button">
                    <NavLink className="button button-apps button-icon" to={props.moreTo}>
                        {props.moreText}
                        <i className="icon icon-chevron-right"></i>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>;
}