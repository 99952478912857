import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { NavLink } from "react-router-dom";
import { storeItemData } from "../StoreItemData";
import { App } from "../objects/StoreItem";
import { useTranslation } from 'react-i18next';

export type StoreMyAppsItemProps = {
	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: App;

	/**
	 * The url of the previous page
	 */
	prevPage: string;

	/**
	 * Key
	 */
	key?: string;
};

export const StoreMyAppsItem = (props: StoreMyAppsItemProps) => {
	const [details, setDetails] = useState({
		tags: [""],
		cost: "",
		icon: "",
	});

	const auth = useAuth();
	const { t } = useTranslation();

	/**
	 * Check whether the store item needs an update, can be installed or is already installed.
	 * @returns button with status of store item
	 */
	const checkUpdate = () => {
		let button;

		switch (props.data.outdated) {
			case true:
				button = (
					<div className="tag has-icon">
						<span className="dot"></span>{t("UPDATE_AVAILABLE")}
					</div>
				);
				break;
				
			case false:
				button = (
					<div className="tag has-icon">
						<span className="icon-tick"></span>{t("INSTALLED")}
					</div>
				);
				break;
			default:
				button = <div className="tag">{details.cost}</div>;
		}

		return button;
	};

	useEffect(() => {
		// Get store item data from json
		storeItemData.forEach((storeItem) => {
			const items = storeItem.items[0];

			if (storeItem.id === props.data.id) {
				setDetails((prevState) => ({
					...prevState,
					tags: items.tags,
					icon: items.icon,
					cost: items.cost,
				}));
			}
		});
	}, [props.data.id]);

	return (
		<div className="card">
			<div className="card-top">
				<div className="image">
					<img src={details.icon} alt={props.data.name} />
				</div>
				<div className="card-content">
					<h3>{props.data.name}</h3>
					<p>
						{details.tags.map((tag, index) => {
							if (index > 0) {
								return <>, {tag}</>;
							} else {
								return <>{tag}</>;
							}
						})}
					</p>
				</div>
			</div>
			<div className="card-bottom">{auth.isAuthenticated ? <>{checkUpdate()}</> : <div className="tag">{details.cost}</div>}</div>
		</div>
	);
};
