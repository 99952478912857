import { useEffect, useState } from "react";
import { StoreItemUpdate } from "../objects/StoreItemUpdate";
import { StoreUpdateItem } from "./StoreUpdateItem";

export type StoreUpdateItemProps = {

	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: StoreItemUpdate[];

	/**
	 * The url of the previous page
	 */
	prevPage: string;

	/**
	 * Trigger update
	 */
	update: (app: StoreItemUpdate) => void;
};

/**
 * Render a list of store items that require updates
 * @param props 
 * @returns 
 */
export const StoreUpdateItems = (props: StoreUpdateItemProps) => {

	const [items, setItems] = useState(props.data);
	useEffect(() => {
		setItems(props.data);
	}, [props.data]);

	return <>
		{items.map((item, idx) => <StoreUpdateItem updateState={item.state} prevPage={props.prevPage} update={(app) => props.update(app)} data={item} key={`${idx}`} />)}
	</>
};
