import { Outlet } from "react-router-dom"
import { Footer } from "./Footer";
import { Header } from "./Header";

/**
 * Main layout of the app
 * @returns 
 */
export const AppLayout = () => {

	return <>
		<Header />
		<main className="content">
			<Outlet />
		</main>
		<Footer />
	</>
};
