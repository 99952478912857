import { useTranslation } from "react-i18next";
import { StoreItem } from "../objects/StoreItem";

export type ExtendedCardBottomProps = {

    /**
     * Contains data needed to build up the object (apps, blocks, extensions)
     */
    storeItem: StoreItem;
}

export const ExtendedCardBottom = (props: ExtendedCardBottomProps) => {
    const { t } = useTranslation();

    return <div className="card-bottom">
        <dl>
            <dd>{t('NAME', { ns: "common" })}</dd>
            <dt>{props.storeItem.name}</dt>
            <dd>{t('AUTHOR', { ns: "viewer" })}</dd>
            <dt>{props.storeItem.author}</dt>
            <dd>{t("VERSION")}</dd>
            <dt>{props.storeItem.version}</dt>
            <dd>{t("LAST_UPDATE")}</dd>
            <dt>
                {new Date(props.storeItem.lastUpdatedAt).toLocaleDateString("nl-NL")}
            </dt>
        </dl>
    </div>
}
