import { AuthProvider } from "react-oidc-context";
import { AuthVerification } from "./AuthVerification";
import { useOidcConfig } from "./hooks/useOidcConfig";
import { StoreRouter } from "./StoreRouter";

/**
 * Main definition of the app
 * @returns 
 */
export const Main = () => {
    const { getOidcConfig } = useOidcConfig();

    return <AuthProvider {...getOidcConfig()}>
        <AuthVerification>
            <StoreRouter />
        </AuthVerification>
    </AuthProvider>
}
