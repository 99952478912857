import { useTranslation } from "react-i18next";

/**
 * Render the top header image on the home page
 * @returns 
 */
export const HomeHeader = () => {
    const { t } = useTranslation();

    return <div className="intro-block">
        <div className="decoration"></div>
        <div className="container">
            <div className="column-left">
                <h1>{t("STORE_TITLE")}</h1>
                <p>
                    {t("STORE_INTRO")}
                </p>
            </div>
            <div className="column-right">
                <img src="./resources/images/illustratie.png" alt="" />
            </div>
        </div>
    </div>;
}