import { HomeHeader } from "./HomeHeader";
import { HomeCategories } from "./HomeCategories";
import { Helmet } from "react-helmet";

/**
 * Home page of the store
 * @returns 
 */
export const Home = () => {


    return (
        <>
            <Helmet>
                <title>GeoApps Store</title>
                <meta property="og:site_name" content="GeoApps Store" />
                <meta property="og:title" content="GeoApps Store" />
                <meta name="twitter:image:alt" content="GeoApps Store" />
                {/* <meta property="og:description" content="{details.subtitle}" /> */}
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={`${window.location.origin}/resources/images/illustratie.png}`} />
            </Helmet>
            <HomeHeader />
            <HomeCategories />
        </>
    )
};
