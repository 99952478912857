import { useTranslation } from 'react-i18next';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';

type AuthVerificationType = {

    children: React.ReactNode;
}

export const AuthVerification = (props: AuthVerificationType): JSX.Element => {
    const auth = useAuth();
    const [signinCheck, setSigninCheck] = useState(false);
    const { i18n } = useTranslation();

    // Set correct language based on user profile
    useEffect(() => {
        if (!auth.user?.profile)
            return;

        const profile = auth.user.profile as any;
        i18n.changeLanguage(profile.language);

    }, [auth.user?.profile]);

    // automatically sign-in
    useEffect(() => {
        if (!signinCheck && !hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinSilent();
            setSigninCheck(true);
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinSilent]);

    return <>{props.children}</>;
};