import { useContext, useEffect, useState } from "react";
import { LoadingIndicatorStoreItems } from "../loading-indicator/LoadingIndicatorStoreItems";
import { LoadingType } from "../types/LoadingTypes";
import { useNavigate } from "react-router-dom";
import { EnvironmentContext } from "../EnvironmentContext";
import { useAuth } from "react-oidc-context";
import { Error } from "../objects/Error";
import { StoreItem } from "../objects/StoreItem";
import { StoreItemUpdate } from "../objects/StoreItemUpdate";
import { useAppUpdater } from "../hooks/useAppUpdater";
import { StoreUpdateItems } from "../store-items/StoreUpdateItems";
import { useTranslation } from 'react-i18next';
import { useStoreBackend } from "../hooks/useStoreBackend";
import { BackButton } from "../BackButton";
import { Helmet } from "react-helmet";

/**
 * Updates page for the store items within the Store
 * This page lists all the updates for the installed apps
 * @returns 
 */
export const Updates = (): JSX.Element => {
	const [storeItems, setStoreItems] = useState<StoreItemUpdate[]>([]);
	const [storeError, setStoreError] = useState<Error>();
	const [loading, setLoading] = useState<boolean>(true);
	const [updateAllInProgress, setUpdateAllInProgress] = useState(false);
	const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
	const { environment } = useContext(EnvironmentContext);
	const auth = useAuth();
	const { t } = useTranslation();
	const appUpdater = useAppUpdater(environment, auth.user.access_token);
	const sb = useStoreBackend();

	// Get apps
	useEffect(() => {

		if (!auth.isAuthenticated)
			return;

		setLoading(true);
		setLoadingFailed(false);
		sb.getStoreItems()
			.then(data => {
				setLoading(false);
				setStoreItems((data as StoreItem[]).filter(i => i.installedComputedVersion !== null && i.outdated).map((app) => {
					const appUpdate = app as StoreItemUpdate;
					appUpdate.state = "eligible";
					return appUpdate;
				}));
			})
			.catch(() => {
				setLoadingFailed(true);
			});
	}, [environment]);

	/**
	 * Start update on all apps
	 */
	const updateAll = async () => {
		if (updateAllInProgress)
			return;

		// Disable update all button
		setUpdateAllInProgress(true);

		// Set app install state to waiting on all apps
		storeItems.forEach((app) => {
			app.state = "waiting";
		});
		setStoreItems(storeItems);

		// Loop over apps to perform installation
		let list = storeItems.map(i => ({ ...i }));
		for (const app of storeItems) {
			await performUpdate(list, app);

			// Update UI
			list = removeAppFromArray(list, app);
			setStoreItems(list);
		}

		setUpdateAllInProgress(false);
	}

	/**
	 * Start the app update
	 * @param appId 
	 */
	const performUpdate = async (list: StoreItemUpdate[], app: StoreItemUpdate) => {

		// Set app install state to waiting
		app.state = "installing";
		setAppState(list, app);

		// Update the app
		return new Promise<void>((resolve) => {

			// Trigger app install
			appUpdater.updateApp(app.id).then(() => {
				resolve();
			});
		});
	}

	/**
	 * Start the app update
	 * @param appId 
	 */
	const updateApp = async (app: StoreItemUpdate) => {

		// Update the app
		return new Promise<void>((resolve) => {

			// Trigger app install
			performUpdate(storeItems, app).then(() => {
				setStoreItems(removeAppFromArray(storeItems, app));
			});
		});
	}

	/**
	 * Update state of app
	 * @param app 
	 */
	const setAppState = (list: StoreItemUpdate[], app: StoreItemUpdate) => {

		const idx = list.findIndex(a => a.id === app.id);
		const newUpdates = [
			...list.slice(0, idx),
			app,
			...list.slice(idx + 1, list.length)
		];

		setStoreItems(newUpdates);
	}

	/**
	 * Remove app from list
	 * @param app 
	 */
	const removeAppFromArray = (list: StoreItemUpdate[], app: StoreItemUpdate) => {

		const idx = list.findIndex(a => a.id === app.id);
		if (idx === -1)
			return list;

		// When just one item is found, remove it
		if (idx === 0 && list.length === 1) {
			return [];
		}

		// Otherwise remove item from list
		const newList = [
			...list.slice(0, idx),
			...list.slice(idx + 1, storeItems.length)
		];
		return newList;
	}

	/**
	 * Desides what to return to the page
	 * @returns Page content
	 */
	const getContent = () => {
		if (loading) {
			return <LoadingIndicatorStoreItems indicator={LoadingType.LOADING} text={t("APPS_LOADING")} />;
		} else if (storeError) {
			return (
				<LoadingIndicatorStoreItems
					indicator={LoadingType.ERROR}
					text={`Er is iets mis gegaan met het inladen van de apps: ${storeError.detail}`}
				/>
			);
		}
		else if (!environment) {
			return <span>{t("CHECK_FOR_UPDATES")}.</span>
		} else if (loadingFailed) {
			return (
				<LoadingIndicatorStoreItems
					indicator={LoadingType.ERROR}
					text={t("APPS_ERROR")}
				/>
			);
		} else if (storeItems.length === 0) {
			return <span>{t("ENVIRONMENT_IS_UPDATED")}</span>
		}
		else {
			return <StoreUpdateItems prevPage="/updates" data={storeItems} update={updateApp} />;
		}
	};

	return (
		<>
			<Helmet>
				<title>{t("UPDATES")} - GeoApps Store</title>
			</Helmet>
			<div className="style-apps cards-block cards-block-list">
				<div className="background"></div>
				<div className="overlay"></div>
				<div className="container">
					<BackButton />
				</div>
				<div className="container">
					<div className="column-left">
						<h2>{t("UPDATES")}</h2>
						<p>{t("MY_UPDATES_DESCRIPTION")}</p>
					</div>
					<div className="column-right">
						{storeItems.length > 0 && <div className="flex justify-content-end">
							<a onClick={updateAll} className={"button button-apps button-icon" + (updateAllInProgress ? " disabled" : "")}>
								{t("UPDATE_ALL")}
								<i className="icon icon-download"></i>
							</a>
						</div>}
						<div className="cards cards-list">
							{getContent()}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
