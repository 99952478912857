import { useTranslation } from "react-i18next";
import { StoreItem } from "../objects/StoreItem";
import { useAuth } from "react-oidc-context";
import { StoreItemDetails } from "../objects/StoreItemDetails";

export type SimpleCardBottomProps = {

    /**
     * Contains data needed to build up the object (apps, blocks, extensions)
     */
    storeItem: StoreItem;

    /**
     * Contains the details of the store item
     */
    details: StoreItemDetails;
}

export const SimpleCardBottom = (props: SimpleCardBottomProps) => {
    const { t } = useTranslation();
    const auth = useAuth();

    /**
     * Check whether the store item needs an update, can be installed or is already installed.
     * @returns button with status of store item
     */
    const checkUpdate = () => {
        let button;

        switch (props.storeItem.outdated) {
            case true:
                button = <div className="status status-update-available"><span className="dot"></span>{t("UPDATE_AVAILABLE")}</div>;
                break;
            case false:
                button = <div className="status status-installed"><span className="icon-tick"></span>{t("INSTALLED")}</div>;
                break;
            default:
                button = <></>;
        }

        return button;
    };

    return <>
        <div className="tag">{t(props.details.cost)}</div>
        {auth.isAuthenticated ? <>{checkUpdate()}</> : <></>}
    </>
}
