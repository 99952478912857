import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { NavLink } from "react-router-dom";
import { useCheckIfHidden } from "../hooks/useCheckIfHidden";
import { StoreItemCard } from "./StoreItemCard";
import { StoreItem } from "../objects/StoreItem";

export type NavItemCardProps = {

	/**
	 * Contains data needed to build up the object (apps, blocks, extensions)
	 */
	data: StoreItem;

	/**
	 * The url of the previous page
	 */
	prevPage: string;

	/**
	 * Key
	 */
	key?: string;
};

/**
 * Render the card for an app, block or extension, which is also a link to the details page
 * @param props 
 * @returns 
 */
export const NavItemCard = (props: NavItemCardProps) => {
	const { checkIfHidden } = useCheckIfHidden();

	return <>
		{!checkIfHidden(props.data.name) && (
			<NavLink className="card" to={`/${props.data.name}`} state={{ prevPage: props.prevPage }}>
				<StoreItemCard data={props.data} />
			</NavLink>
		)}
	</>
};
