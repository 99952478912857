import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { EnvironmentContext } from "./EnvironmentContext";
import { useState } from "react";
import { Home } from "./Home";
import { Apps } from "./pages/Apps";
import { MyApps } from "./pages/MyApps";
import { Updates } from "./pages/Updates";
import { Blocks } from "./pages/Blocks";
import { Extensions } from "./pages/Extensions";
import { DetailPage } from "./DetailPage";
import { OidcSigninCallback } from "./OidcSigninCallback";
import { AppLayout } from "./AppLayout";
import { useAuth } from "react-oidc-context";
import { StoreTable } from "./pages/StoreTable";

/**
 * Contains the routing of the store
 * @returns
 */
export const StoreRouter = () => {
	const [environment, setEnvironment] = useState<string>("");
	const auth = useAuth();
	return (
		<BrowserRouter>
			<ScrollToTop />
			<EnvironmentContext.Provider value={{ environment: environment, setEnvironment: setEnvironment }}>
				<Routes>
					<Route path="/oidc-signin" element={<OidcSigninCallback />} />
					<Route path="/" element={<AppLayout />}>
						<Route index element={<Home />} />
						<Route path="apps" element={<StoreTable identifier="App" />} />
						<Route path="blocks" element={<StoreTable identifier="Block" />} />
						<Route path="extensions" element={<StoreTable identifier="Extension" />} />
						{auth.isAuthenticated && auth.user && (
							<>
								<Route path="myapps" element={<MyApps />} />
								<Route path="updates" element={<Updates />} />
							</>
						)}
						<Route path=":name" element={<DetailPage />} />
					</Route>
				</Routes>
			</EnvironmentContext.Provider>
		</BrowserRouter>
	);
};
