import { useStoreBackend } from "./useStoreBackend";

export const useAppUpdater = (environment: string, access_token: string) => {
    const sb = useStoreBackend();

    const updateApp = (appId: string): Promise<void> => {
        console.log(`Update app ${appId} on ${environment}`);

        return new Promise<void>((resolve, reject) => {

            sb.installStoreItem(appId)
                .then(() => {
                    resolve();
                    return;
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    return {
        updateApp
    }
}
