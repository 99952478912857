import { Trans, useTranslation } from "react-i18next";


/**
 * Contains the footer of the store
 * @returns 
 */
export const Footer = () => {
    const { t } = useTranslation();

    return <footer className="site-footer">
        <div className="site-footer-top">
            <div className="container">
                <div className="column-left">
                    <img src="./resources/images/footer-illustration.png" alt="Powered by GeoApps" />
                </div>
                <div className="column-right">
                    <h2>{t("POWERED_BY")}</h2>
                    <p>
                        <Trans i18nKey="POWERED_BY_DESCRIPTION" components={{ 
                            link1: <a href="https://geoapps.nl" target="_blank" rel="noopener noreferrer" /> }} 
                        />
                    </p>
                    <h2>{t("FOR_DEVELOPERS")}</h2>
                    <p>
                    <Trans i18nKey="FOR_DEVELOPERS_DESCRIPTION" components={{ 
                            link1: <a href="https://geoapps.io" target="_blank" rel="noopener noreferrer" /> }} 
                        />
                    </p>
                    <h2>{t("MAPGEAR")}</h2>
                    <p>
                        <Trans i18nKey="MAPGEAR_DESCRIPTION" components={{ 
                            link1: <a href="https://www.mapgear.nl" target="_blank" rel="noopener noreferrer" /> }} 
                        />
                    </p>
                </div>
            </div>
        </div>
        <div className="site-footer-bottom">
            <div className="container">
                <div className="copyright">
                    &copy; 2023 MapGear B.V.
                </div>
                <div>
                    <a href="https://privacy.geoapps.nl/" target="_blank" rel="noopener noreferrer">
                        {t("PRIVACY_POLICY", { "ns": "glossary" })}
                    </a>
                </div>
            </div>
        </div>
    </footer>
}