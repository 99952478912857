import { useTranslation } from "react-i18next";

/**
 * UI Element used to show the signin message for the user
 * @returns 
 */
export const OidcSigninCallback = () => {
    const { t } = useTranslation();

    return <>{t("SIGNING_YOU_IN")}</>;
}
