import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next
    .use(initReactI18next)
    .use(Backend)
    // init i18next
    .init({
        ns: ["common", "store", "glossary", "overview", "viewer"],
        defaultNS: "store",
        load: "currentOnly",
        fallbackLng: "en-US",
        supportedLngs: ["en-US", "fr-FR", "de-DE", "nl-NL"],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: "./locales/{{lng}}/{{ns}}.json"
        }
    });
    