import { createContext, SetStateAction, Dispatch } from "react";

export type EnvironmentsType = {
    /**
     * Current chosen environment
     */
    environment: string;

    /**
     * Environment setter
     */
    setEnvironment?: Dispatch<SetStateAction<string>>
};

export const EnvironmentContext = createContext<EnvironmentsType>({
    environment: ""
});