import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { EnvironmentContext } from "../EnvironmentContext";
import { ItemList } from "../store-items/ItemList";
import { LoadingType } from "../types/LoadingTypes";
import { LoadingIndicatorStoreItems } from "../loading-indicator/LoadingIndicatorStoreItems";
import { BackButton } from "../BackButton";
import { useStoreBackend } from "../hooks/useStoreBackend";
import { GroupedStoreItem } from "../objects/GroupedStoreItem";

export type StoreTableProps = {
    /**
     * Type of the store table
     */
    identifier: "App" | "Block" | "Extension";
}

/**
 * Represent a store table, e.g. for displaying all apps, blocks or extensions
 * @param props - The props
 * @returns The store table
 */
export const StoreTable = (props: StoreTableProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const auth = useAuth();
    const { t } = useTranslation();
    const { environment } = useContext(EnvironmentContext);
    const [storeItems, setStoreItems] = useState<GroupedStoreItem[]>([]);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);
    const sb = useStoreBackend();
    const location = useLocation();

    // Get apps
    useEffect(() => {

        sb.getStoreItems({ groupBy: "ManifestType" })
            .then(data => {
                setLoading(false);
                setStoreItems(data as GroupedStoreItem[]);
            })
            .catch(error => {
                setLoadingFailed(true);
            });
    }, [environment]);

    const getTitle = () => {
        switch (props.identifier) {
            case "App":
                return t("APPS_TITLE");
            case "Block":
                return t("BLOCKS_TITLE");
            case "Extension":
                return t("EXTENSIONS_TITLE");
        }
    }

    const getDescription = () => {
        switch (props.identifier) {
            case "App":
                return t("APPS_DESCRIPTION");
            case "Block":
                return t("BLOCKS_DESCRIPTION");
            case "Extension":
                return t("EXTENSIONS_DESCRIPTION");

        }
    }

    /**
     * Get the class name based on the identifier
     * @returns The class name
     */
    const getClassName = () => {
        switch (props.identifier) {
            case "App":
                return "style-apps";
            case "Block":
                return "style-blocks";
            case "Extension":
                return "style-extensions";
        }
    }

    /**
     * Render the item list
     * @returns The item list
     */
    const getItemList = () => {
        if (loading) {
            return <LoadingIndicatorStoreItems indicator={LoadingType.LOADING} text={t("APPS_LOADING")} />
        } else if (loadingFailed) {
            return <LoadingIndicatorStoreItems indicator={LoadingType.ERROR} text={t("APPS_ERROR")} />
        }

        const group = storeItems.filter(i => i.identifier === props.identifier)[0];
        return <ItemList data={group} prevPage={location.pathname} groupKey={0} key={`${props.identifier}`} />;
    }

    return <>
        <Helmet>
            <title>{getTitle()} - GeoApps Store</title>
        </Helmet>
        <div className={`cards-block cards-block-list ${getClassName()}`}>
            <div className="background"></div>
            <div className="overlay"></div>
            <div className="container">
                <BackButton to="/" />
            </div>
            <div className="container">
                <div className="column-left">
                    <h2>{getTitle()}</h2>
                    <p>
                        {getDescription()}
                    </p>
                </div>
                <div className="column-right">
                    <div className="cards">{getItemList()}</div>
                </div>
            </div>
        </div>
    </>
}
