import { LoadingType } from "../types/LoadingTypes";

export type LoadingIndicatorProps = {
	/**
	 * Text of the loading indicator
	 */
	text?: string;

	/**
	 * Enum to determine what indicator to show
	 */
	indicator: LoadingType;
};

export const LoadingIndicatorStoreItems = (props: LoadingIndicatorProps): JSX.Element => {

	// Determine what indicator to show.
	let className = "";
	switch (props.indicator) {
		case LoadingType.LOADING:
			className = "loading-indicator";
			break;
		case LoadingType.ERROR:
			className = "loading-error";
			break;
	};

	return (
		<div className={`indicator ${props.indicator === LoadingType.ERROR ? "has-error" : ""}`}>
			<div className="indicator-inner">
				{props.text && <div className="indicator-title">{props.text}</div>}
				<div className={className}></div>
			</div>
		</div>
	);
};
