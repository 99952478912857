import { storeItemData } from "../StoreItemData";

/**
 * Checks if an app or other store item is hidden or not
 */
export const useCheckIfHidden = () => {

    /**
     * Checks if an app or other store item is hidden or not
     * @param name Name of the app
     * @returns boolean. True when app is hidden, false when app is not hidden
     */
    const checkIfHidden = (name: string | undefined): boolean => {
        const storeItem = storeItemData.find((item: any) => item.name.toUpperCase() === name?.toUpperCase())

        if (storeItem === undefined) {
            return true
        }
        else {
            return false
        }
    }

    return {
        checkIfHidden
    }
};
