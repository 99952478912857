import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Environments } from "./header/Environments";
import { ProfileMenu } from "./header/ProfileMenu";

/**
 * Returns the header of the store
 * @returns 
 */
export const Header = () => {
    const { t } = useTranslation();

    return <header className="header">
        <div className="inner">
            <div className="container">
                <NavLink to={"/"} className="logo">
                    <img src="/resources/images/logo-geoapps.svg" width="165" height="45" alt="GeoApps" />
                </NavLink>
                <div className="column-right semibold">
                    <Environments />
                    <h1 className="h1">{t("STORE")}</h1>
                    <ProfileMenu />
                </div>
            </div>
        </div>
    </header>;
}